import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
import AdministrationBlockLayout from '../block-layout';
export default class AnonymizeGarImport extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    session;
    @tracked
    isLoading = false;
    @action
    async anonymizeGar(files1) {
        this.isLoading = true;
        try {
            const token1 = this.session.data.authenticated.access_token;
            const response1 = await window.fetch(`${ENV.APP.API_HOST}/api/admin/anonymize/gar`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'text/csv',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: files1[0]
            });
            const json1 = await response1.json();
            if (response1.ok) {
                const { 'gar-anonymized-user-count': garAnonymizedUserCount1, total: total1 } = json1.data.attributes;
                if (garAnonymizedUserCount1 === total1) {
                    return this.pixToast.sendSuccessNotification({
                        message: this.intl.t('components.administration.anonymize-gar-import.notifications.success.full', {
                            total: total1
                        })
                    });
                }
                return this.pixToast.sendWarningNotification({
                    message: this.intl.t('components.administration.anonymize-gar-import.notifications.success.partial', {
                        garAnonymizedUserCount: garAnonymizedUserCount1,
                        total: total1
                    })
                });
            }
            const error1 = json1.errors[0];
            if (error1.code === 'PAYLOAD_TOO_LARGE') {
                return this.pixToast.sendErrorNotification({
                    message: this.intl.t('components.administration.anonymize-gar-import.notifications.error.payload-too-large', {
                        maxSize: error1.meta.maxSize
                    })
                });
            }
            this.errorResponseHandler.notify(await response1.json());
        } catch  {
            this.pixToast.sendErrorNotification({
                message: this.intl.t('common.notifications.generic-error')
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.anonymize-gar-import.title"}}
      @description={{t "components.administration.anonymize-gar-import.description"}}
    >
      <PixButtonUpload
        @id="anonymize-gar-upload"
        @onChange={{this.anonymizeGar}}
        @variant="secondary"
        disabled={{this.isLoading}}
        accept=".csv"
      >
        {{#if this.isLoading}}
          {{t "common.forms.loading"}}
        {{else}}
          {{t "components.administration.anonymize-gar-import.upload-button"}}
        {{/if}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
