import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import includes from 'lodash/includes';
import { assessmentResultStatus, assessmentStates } from 'pix-admin/models/certification';
export default class CertificationStatusComponent extends Component {
    get isStatusBlocking() {
        const blockingStatuses1 = [
            assessmentStates.STARTED,
            assessmentResultStatus.ERROR,
            assessmentStates.ENDED_BY_SUPERVISOR
        ];
        return includes(blockingStatuses1, this.args.record.status) || this.args.record.isFlaggedAborted;
    }
    static{
        template(`
    <span class="{{if this.isStatusBlocking 'certification-list-page__cell--important'}}">
      {{@record.statusLabel}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
