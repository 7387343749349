import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import PixMultiSelect from '@1024pix/pix-ui/components/pix-multi-select';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Card from '../card';
import Areas from './tubes-selection/areas';
const MAX_TUBE_LEVEL = 8;
export default class TubesSelection extends Component {
    @service
    pixToast;
    @tracked
    selectedFrameworkIds = [];
    @tracked
    selectedTubeIds = [];
    @tracked
    totalTubesCount = 0;
    @tracked
    areas;
    @tracked
    tubeLevels = {};
    constructor(...args1){
        super(...args1);
        if (this.args.initialAreas?.length > 0) {
            this.setInitialFrameworks().then(this.refreshAreas);
        } else {
            this.setDefaultFrameworks();
            this.refreshAreas();
        }
        if (this.args.initialCappedTubes?.length > 0) {
            this.setInitialCheckedTubes();
        }
    }
    setInitialFrameworks() {
        return Promise.resolve(this.args.initialAreas).then((initialAreas1)=>{
            const initialAreasFrameworks1 = initialAreas1.map((area3)=>area3.frameworkId);
            this.selectedFrameworkIds = Array.from(new Set(initialAreasFrameworks1));
        });
    }
    setDefaultFrameworks() {
        const pixFramework1 = this.args.frameworks.find((framework1)=>framework1.name === 'Pix');
        this.selectedFrameworkIds = [
            pixFramework1.id
        ];
    }
    setInitialCheckedTubes() {
        this.selectedTubeIds = this.args.initialCappedTubes.map((tube1)=>{
            this.tubeLevels[tube1.id] = tube1.level;
            return tube1.id;
        });
    }
    get frameworkOptions() {
        return this.args.frameworks.map((framework1)=>{
            return {
                label: framework1.name,
                value: framework1.id
            };
        });
    }
    get selectedFrameworks() {
        return this.args.frameworks.filter((framework1)=>this.selectedFrameworkIds.includes(framework1.id));
    }
    get hasNoFrameworksSelected() {
        return this.selectedFrameworkIds.length === 0;
    }
    @action
    setSelectedFrameworkIds(frameworkIds1) {
        this.selectedFrameworkIds = frameworkIds1;
        this.refreshAreas();
    }
    @action
    checkTube(tube1) {
        if (this.selectedTubeIds.includes(tube1.id)) {
            return;
        }
        this.selectedTubeIds.pushObject(tube1.id);
        this._triggerOnChange();
    }
    @action
    uncheckTube(tube1) {
        const index1 = this.selectedTubeIds.indexOf(tube1.id);
        if (index1 === -1) {
            return;
        }
        this.selectedTubeIds.removeAt(index1);
        this._triggerOnChange();
    }
    @action
    setLevelTube(tubeId1, level1) {
        this.tubeLevels = {
            ...this.tubeLevels,
            [tubeId1]: parseInt(level1)
        };
        this._triggerOnChange();
    }
    @action
    async refreshAreas() {
        const selectedFrameworksAreas1 = (await Promise.all(this.selectedFrameworks.map(async (framework1)=>{
            if (framework1.areas.isFulfilled) await framework1.areas.reload();
            const frameworkAreas1 = await framework1.areas;
            return frameworkAreas1.toArray();
        }))).flat();
        this.totalTubesCount = await this._calculateNumberOfTubes(selectedFrameworksAreas1);
        this.areas = selectedFrameworksAreas1.sort((area11, area21)=>{
            return area11.code - area21.code;
        });
        this._triggerOnChange();
    }
    @action
    fillTubesSelectionFromFile([file1]) {
        const reader1 = new FileReader();
        reader1.addEventListener('load', (event1)=>this._onFileLoad(event1));
        reader1.readAsText(file1);
    }
    _triggerOnChange() {
        const selectedTubesWithLevel1 = this._getSelectedTubesWithLevel();
        this.args.onChange(selectedTubesWithLevel1);
    }
    get selectedTubesCount() {
        return this._selectedTubes.length;
    }
    get _selectedTubes() {
        return (this.areas?.flatMap((area3)=>{
            const competences1 = area3.hasMany('competences').value();
            return competences1.flatMap((competence1)=>{
                const thematics1 = competence1.hasMany('thematics').value();
                return thematics1.flatMap((thematic1)=>thematic1.hasMany('tubes').value());
            });
        }).filter((tube1)=>this.selectedTubeIds.includes(tube1.id)) ?? []);
    }
    async _onFileLoad(event1) {
        try {
            const data1 = JSON.parse(event1.target.result);
            if (!Array.isArray(data1)) throw new Error("Le format du fichier n'est pas reconnu.");
            if (data1.length === 0) throw new Error('Le fichier ne contient aucun élément.');
            if (typeof data1[0] === 'string') {
                this._loadTubesPreselection(data1);
            } else if (typeof data1[0] === 'object' && typeof data1[0].id === 'string') {
                this._loadTargetProfile(data1);
            } else {
                throw new Error("Le format du fichier n'est pas reconnu.");
            }
            await this.refreshAreas();
            this._triggerOnChange();
            this.pixToast.sendSuccessNotification({
                message: 'Fichier bien importé.'
            });
        } catch (error1) {
            this.pixToast.sendErrorNotification({
                message: error1.message
            });
        }
    }
    _loadTubesPreselection(tubeIds1) {
        this.selectedTubeIds = tubeIds1;
        this.tubeLevels = {};
        this.setDefaultFrameworks();
    }
    _loadTargetProfile(tubes1) {
        this.selectedTubeIds = tubes1.map(({ id: id1 })=>id1);
        this.tubeLevels = Object.fromEntries(tubes1.map(({ id: id1, level: level1 })=>[
                id1,
                level1
            ]));
        if (tubes1[0].frameworkId) {
            this.selectedFrameworkIds = [
                ...new Set(tubes1.map(({ frameworkId: frameworkId1 })=>frameworkId1))
            ];
        } else {
            this.setDefaultFrameworks();
        }
    }
    async _calculateNumberOfTubes(areas1) {
        const competences1 = (await Promise.all(areas1.map(async (area3)=>await area3.hasMany('competences').value()))).flat();
        const thematics1 = (await Promise.all(competences1.map(async (competence1)=>await competence1.hasMany('thematics').value()))).flat();
        const tubes1 = (await Promise.all(thematics1.map(async (thematic1)=>await thematic1.hasMany('tubes').value()))).flat();
        return tubes1.length;
    }
    _getSelectedTubesWithLevel() {
        return this._selectedTubes.map((tube1)=>{
            const level1 = this.tubeLevels[tube1.id] ?? MAX_TUBE_LEVEL;
            return {
                id: tube1.id,
                level: level1
            };
        });
    }
    static{
        template(`
    <div class="tubes-selection">
      <Card class="tubes-selection__card" @title="Sélection des sujets">
        <div class="tubes-selection__inline-layout">
          <PixMultiSelect
            class="tubes-selection__multi-select"
            @placeholder="Sélectionner les référentiels souhaités"
            @id="framework-list"
            @isSearchable={{true}}
            @inlineLabel={{true}}
            @showOptionsOnInput={{true}}
            @onChange={{this.setSelectedFrameworkIds}}
            @emptyMessage="Pas de résultat"
            @values={{this.selectedFrameworkIds}}
            @options={{this.frameworkOptions}}
          >
            <:label>Référentiels :</:label>
            <:default as |option|>{{option.label}}</:default>
          </PixMultiSelect>
          {{#if @displayJsonImportButton}}
            <div class="tubes-selection__vertical-delimiter"></div>
            <PixButtonUpload
              @onChange={{this.fillTubesSelectionFromFile}}
              @variant="secondary"
              @size="small"
              @id="file-upload"
              accept=".json"
            >
              Importer un fichier JSON
            </PixButtonUpload>
          {{/if}}
          <PixTag class="tubes-selection__count" @color="neutral">
            {{this.selectedTubesCount}}/{{this.totalTubesCount}}
            sujet(s) sélectionné(s)
          </PixTag>
        </div>
      </Card>

      {{#if this.hasNoFrameworksSelected}}
        Aucun référentiel de sélectionné
      {{else}}
        <Areas
          @areas={{this.areas}}
          @setLevelTube={{this.setLevelTube}}
          @selectedTubeIds={{this.selectedTubeIds}}
          @checkTube={{this.checkTube}}
          @uncheckTube={{this.uncheckTube}}
          @tubeLevels={{this.tubeLevels}}
          @displayDeviceCompatibility={{@displayDeviceCompatibility}}
          @displaySkillDifficultyAvailability={{@displaySkillDifficultyAvailability}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
