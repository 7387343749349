import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { concat, fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { notEq, or } from 'ember-truth-helpers';
import lodashGet from 'lodash/get';
import set from 'lodash/set';
import Organization from 'pix-admin/models/organization';
export default class OrganizationInformationSectionEditionMode extends Component {
    @service
    accessControl;
    @service
    store;
    @service
    oidcIdentityProviders;
    @tracked
    isEditMode = false;
    @tracked
    showArchivingConfirmationModal = false;
    noIdentityProviderOption = {
        label: 'Aucun',
        value: 'None'
    };
    garIdentityProviderOption = {
        label: 'GAR',
        value: 'GAR'
    };
    constructor(){
        super(...arguments);
        this.form = this.store.createRecord('organization-form');
        this._initForm();
    }
    get isManagingStudentAvailable() {
        return (!this.args.organization.isLearnerImportEnabled && (this.args.organization.isOrganizationSCO || this.args.organization.isOrganizationSUP));
    }
    get identityProviderOptions() {
        const oidcIdentityProvidersOptions1 = this.oidcIdentityProviders.list.map((identityProvider1)=>({
                value: identityProvider1.code,
                label: identityProvider1.organizationName
            }));
        return [
            this.noIdentityProviderOption,
            this.garIdentityProviderOption,
            ...oidcIdentityProvidersOptions1
        ];
    }
    @action
    onChangeIdentityProvider(newIdentityProvider1) {
        this.form.identityProviderForCampaigns = newIdentityProvider1;
    }
    @action
    closeAndResetForm() {
        this.args.toggleEditMode();
        this._initForm();
    }
    @action
    updateFormCheckBoxValue(key1) {
        set(this.form, key1, !lodashGet(this.form, key1));
    }
    @action
    updateFormValue(key1, event1) {
        this.form[key1] = event1.target.value;
    }
    @action
    async updateOrganization(event1) {
        event1.preventDefault();
        const { validations: validations1 } = await this.form.validate();
        if (!validations1.isValid) {
            return;
        }
        if (this.form.identityProviderForCampaigns === 'None') {
            this.form.identityProviderForCampaigns = null;
        }
        this.args.organization.set('name', this.form.name);
        this.args.organization.set('externalId', this.form.externalId);
        this.args.organization.set('provinceCode', this.form.provinceCode);
        this.args.organization.set('dataProtectionOfficerFirstName', this.form.dataProtectionOfficerFirstName);
        this.args.organization.set('dataProtectionOfficerLastName', this.form.dataProtectionOfficerLastName);
        this.args.organization.set('dataProtectionOfficerEmail', this.form.dataProtectionOfficerEmail);
        this.args.organization.set('email', this.form.email);
        this.args.organization.set('credit', this.form.credit);
        this.args.organization.set('documentationUrl', this.form.documentationUrl);
        this.args.organization.set('identityProviderForCampaigns', this.form.identityProviderForCampaigns);
        this.args.organization.set('features', this.form.features);
        this.closeAndResetForm();
        return this.args.onSubmit();
    }
    _initForm() {
        this.form.name = this.args.organization.name;
        this.form.externalId = this.args.organization.externalId;
        this.form.provinceCode = this.args.organization.provinceCode;
        this.form.dataProtectionOfficerFirstName = this.args.organization.dataProtectionOfficerFirstName;
        this.form.dataProtectionOfficerLastName = this.args.organization.dataProtectionOfficerLastName;
        this.form.dataProtectionOfficerEmail = this.args.organization.dataProtectionOfficerEmail;
        this.form.email = this.args.organization.email;
        this.form.credit = this.args.organization.credit;
        this.form.documentationUrl = this.args.organization.documentationUrl;
        this.form.identityProviderForCampaigns = this.args.organization.identityProviderForCampaigns ?? this.noIdentityProviderOption.value;
        this.form.features = structuredClone(this.args.organization.features);
    }
    static{
        template(`
    <div class="organization__edit-form">
      <form class="form" {{on "submit" this.updateOrganization}}>

        <span class="form__instructions">
          {{t "common.forms.mandatory-fields" htmlSafe=true}}
        </span>

        <div class="form-field">
          <PixInput
            required={{true}}
            @requiredLabel="obligatoire"
            @errorMessage={{this.form.nameError.message}}
            @validationStatus={{this.form.nameError.status}}
            @value={{this.form.name}}
            {{on "input" (fn this.updateFormValue "name")}}
          ><:label>
              Nom
            </:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.externalIdError.message}}
            @validationStatus={{this.form.externalIdError.status}}
            @value={{this.form.externalId}}
            {{on "input" (fn this.updateFormValue "externalId")}}
          ><:label>Identifiant externe</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.provinceCodeError.message}}
            @validationStatus={{this.form.provinceCodeError.status}}
            @value={{this.form.provinceCode}}
            {{on "input" (fn this.updateFormValue "provinceCode")}}
          ><:label>Département (en 3 chiffres)</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.dataProtectionOfficerFirstNameError.message}}
            @validationStatus={{this.form.dataProtectionOfficerFirstNameError.status}}
            @value={{this.form.dataProtectionOfficerFirstName}}
            {{on "input" (fn this.updateFormValue "dataProtectionOfficerFirstName")}}
          ><:label>Prénom du DPO</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.dataProtectionOfficerLastNameError.message}}
            @validationStatus={{this.form.dataProtectionOfficerLastNameError.status}}
            @value={{this.form.dataProtectionOfficerLastName}}
            {{on "input" (fn this.updateFormValue "dataProtectionOfficerLastName")}}
          ><:label>Nom du DPO</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.dataProtectionOfficerEmailError.message}}
            @validationStatus={{this.form.dataProtectionOfficerEmailError.status}}
            @value={{this.form.dataProtectionOfficerEmail}}
            {{on "input" (fn this.updateFormValue "dataProtectionOfficerEmail")}}
          ><:label>Adresse e-mail du DPO</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            type="number"
            @errorMessage={{this.form.creditError.message}}
            @validationStatus={{this.form.creditError.status}}
            @value={{this.form.credit}}
            {{on "input" (fn this.updateFormValue "credit")}}
          ><:label>Crédits</:label></PixInput>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.documentationUrlError.message}}
            @validationStatus={{this.form.documentationUrlError.status}}
            @value={{this.form.documentationUrl}}
            {{on "input" (fn this.updateFormValue "documentationUrl")}}
          ><:label>Lien vers la documentation</:label></PixInput>
        </div>

        <div class="form-field">
          <PixSelect
            @options={{this.identityProviderOptions}}
            @value={{this.form.identityProviderForCampaigns}}
            @onChange={{this.onChangeIdentityProvider}}
            @hideDefaultOption={{true}}
          >
            <:label>SSO</:label>
          </PixSelect>
        </div>

        <div class="form-field">
          <PixInput
            @errorMessage={{this.form.emailError.message}}
            @validationStatus={{this.form.emailError.status}}
            @value={{this.form.email}}
            {{on "input" (fn this.updateFormValue "email")}}
          ><:label>Adresse e-mail d'activation SCO</:label></PixInput>
        </div>

        <FeaturesForm
          @features={{this.form.features}}
          @updateFormCheckBoxValue={{this.updateFormCheckBoxValue}}
          @isManagingStudentAvailable={{this.isManagingStudentAvailable}}
        />

        <div class="form-actions">
          <PixButton @size="small" @variant="secondary" @triggerAction={{this.closeAndResetForm}}>
            {{t "common.actions.cancel"}}
          </PixButton>
          <PixButton @type="submit" @size="small" @variant="success">
            {{t "common.actions.save"}}
          </PixButton>
        </div>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function keys(obj1) {
    return Object.keys(obj1);
}
const FeaturesForm = template(`
  {{#each (keys Organization.editableFeatureList) as |feature|}}
    {{#let
      (get @features feature) (concat "components.organizations.information-section-view.features." feature)
      as |organizationFeature featureLabel|
    }}
      {{#if (or @isManagingStudentAvailable (notEq feature "IS_MANAGING_STUDENTS"))}}
        <div class="form-field">
          <PixCheckbox
            @checked={{organizationFeature.active}}
            {{on "change" (fn @updateFormCheckBoxValue (concat "features." feature ".active"))}}
          ><:label>{{t featureLabel}}</:label></PixCheckbox>
        </div>
      {{/if}}
    {{/let}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
