import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
import AdministrationBlockLayout from '../block-layout';
export default class AddOrganizationFeaturesInBatch extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    session;
    @service
    errorResponseHandler;
    @action
    async addOrganizationFeaturesInBatch(files1) {
        let response1;
        try {
            const fileContent1 = files1[0];
            const token1 = this.session.data.authenticated.access_token;
            response1 = await window.fetch(`${ENV.APP.API_HOST}/api/admin/organizations/add-organization-features`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'text/csv',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: fileContent1
            });
            if (response1.ok) {
                this.pixToast.sendSuccessNotification({
                    message: this.intl.t('components.administration.add-organization-features-in-batch.notifications.success')
                });
                return;
            } else {
                this.errorResponseHandler.notify(await response1.json());
            }
        } catch  {
            this.pixToast.sendErrorNotification({
                message: this.intl.t('common.notifications.generic-error')
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.add-organization-features-in-batch.title"}}
      @description={{t "components.administration.add-organization-features-in-batch.description"}}
    >
      <PixButtonUpload
        @id="organizations-batch-update-file-upload"
        @onChange={{this.addOrganizationFeaturesInBatch}}
        @variant="secondary"
        accept=".csv"
      >
        {{t "components.administration.add-organization-features-in-batch.upload-button"}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
