import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import fetch from 'fetch';
import ENV from 'pix-admin/config/environment';
import AdministrationBlockLayout from '../block-layout';
export default class OidcProvidersImport extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    session;
    @action
    async importOidcProviders(files1) {
        let response1;
        try {
            const fileContent1 = files1[0];
            const token1 = this.session.data.authenticated.access_token;
            response1 = await fetch(`${ENV.APP.API_HOST}/api/admin/oidc-providers/import`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: fileContent1
            });
            if (response1.ok) {
                this.pixToast.sendSuccessNotification({
                    message: this.intl.t('components.administration.oidc-providers-import.notifications.success')
                });
                return;
            }
            const jsonResponse1 = await response1.json();
            if (!jsonResponse1.errors) {
                throw new Error('Generic error');
            }
            jsonResponse1.errors.forEach((error1)=>{
                this.pixToast.sendErrorNotification({
                    message: error1.detail
                });
            });
        } catch  {
            this.pixToast.sendErrorNotification({
                message: this.intl.t('common.notifications.generic-error')
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.oidc-providers-import.title"}}
      @description={{t "components.administration.oidc-providers-import.description"}}
    >
      <PixButtonUpload
        @id="oidc-providers-file-upload"
        @onChange={{this.importOidcProviders}}
        @variant="secondary"
        accept=".json"
      >
        {{t "components.administration.oidc-providers-import.upload-button"}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
