import { template } from "@ember/template-compiler";
import AddOrganizationFeaturesInBatch from './add-organization-features-in-batch';
import LearningContent from './learning-content';
import UpsertQuestsInBatch from './upsert-quests-in-batch';
export default template(`
  <LearningContent />
  <AddOrganizationFeaturesInBatch />
  <UpsertQuestsInBatch />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
