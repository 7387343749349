import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { concat, get } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import ENV from 'pix-admin/config/environment';
import Organization from 'pix-admin/models/organization';
export default class OrganizationInformationSection extends Component {
    @service
    oidcIdentityProviders;
    @service
    accessControl;
    @service
    intl;
    @tracked
    tags;
    @tracked
    hasOrganizationChildren;
    constructor(){
        super(...arguments);
        if (this.args.organization.tags) {
            Promise.resolve(this.args.organization.tags).then((tags1)=>{
                this.tags = tags1;
            });
        }
        if (this.args.organization.children) {
            Promise.resolve(this.args.organization.children).then((children1)=>{
                this.hasOrganizationChildren = children1.length > 0;
            });
        }
    }
    get isManagingStudentAvailable() {
        return (!this.args.organization.isLearnerImportEnabled && (this.args.organization.isOrganizationSCO || this.args.organization.isOrganizationSUP));
    }
    get identityProviderName() {
        const GARIdentityProvider1 = {
            code: 'GAR',
            organizationName: 'GAR'
        };
        const allIdentityProviderList1 = [
            ...this.oidcIdentityProviders.list,
            GARIdentityProvider1
        ];
        const identityProvider1 = allIdentityProviderList1.findBy('code', this.args.organization.identityProviderForCampaigns);
        const identityProviderName1 = identityProvider1?.organizationName;
        return identityProviderName1 ?? 'Aucun';
    }
    get externalURL() {
        const urlDashboardPrefix1 = ENV.APP.ORGANIZATION_DASHBOARD_URL;
        return urlDashboardPrefix1 && urlDashboardPrefix1 + this.args.organization.id;
    }
    static{
        template(`
    <div class="organization__data">
      <h2 class="organization__name">{{@organization.name}}</h2>

      {{#if this.tags}}
        <ul class="organization-tags-list">
          {{#each @organization.tags as |tag|}}
            <li class="organization-tags-list__tag">
              <PixTag @color="purple-light">{{tag.name}}</PixTag>
            </li>
          {{/each}}
        </ul>
      {{else}}
        <PixNotificationAlert class="organization-information-section__missing-tags-message" @type="information">Cette
          organisation n'a pas de tags.
        </PixNotificationAlert>
      {{/if}}
      <div class="organization__network-label">
        {{#if this.hasOrganizationChildren}}
          <PixTag @color="success">{{t
              "components.organizations.information-section-view.parent-organization"
            }}</PixTag>
        {{/if}}
        {{#if @organization.parentOrganizationId}}
          <ul>
            <li>
              <PixTag class="organization__child-tag" @color="success">{{t
                  "components.organizations.information-section-view.child-organization"
                }}</PixTag>
            </li>
            <li>
              {{t "components.organizations.information-section-view.parent-organization"}}
              :
              <LinkTo @route="authenticated.organizations.get" @model={{@organization.parentOrganizationId}}>
                {{@organization.parentOrganizationName}}
              </LinkTo>
            </li>
          </ul>
        {{/if}}
      </div>
      {{#if @organization.isArchived}}
        <PixNotificationAlert class="organization-information-section__archived-message" @type="warning">
          Archivée le
          {{@organization.archivedFormattedDate}}
          par
          {{@organization.archivistFullName}}.
        </PixNotificationAlert>
      {{/if}}

      <div class="organization-information-section__content">
        <div class="organization-information-section__details">
          <ul class="organization-information-section__details__list">
            <li>Type : {{@organization.type}}</li>
            <li>Créée par : {{@organization.creatorFullName}} ({{@organization.createdBy}})</li>
            <li>Créée le : {{@organization.createdAtFormattedDate}}</li>
            {{#if @organization.externalId}}
              <li>Identifiant externe : {{@organization.externalId}}</li>
            {{/if}}
            {{#if @organization.provinceCode}}
              <li>Département : {{@organization.provinceCode}}</li>
            {{/if}}

            <br />

            <li>Nom du DPO : {{@organization.dataProtectionOfficerFullName}}</li>
            <li>Adresse e-mail du DPO : {{@organization.dataProtectionOfficerEmail}}</li>
            <br />
            <li>Crédits : {{@organization.credit}}</li>
            <li>Lien vers la documentation :
              {{#if @organization.documentationUrl}}
                <a
                  href="{{@organization.documentationUrl}}"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{@organization.documentationUrl}}</a>
              {{else}}
                Non spécifié
              {{/if}}
            </li>
            <li>SSO : {{this.identityProviderName}}</li>

            <br />

            <li>Adresse e-mail d'activation SCO : {{@organization.email}}</li>
            {{#if @organization.code}}
              <br />
              <li>Code : {{@organization.code}}</li>
            {{/if}}
          </ul>
          <FeaturesSection @features={{@organization.features}} />
          {{#if this.accessControl.hasAccessToOrganizationActionsScope}}
            <div class="form-actions">
              <PixButton @variant="secondary" @size="small" @triggerAction={{@toggleEditMode}}>
                {{t "common.actions.edit"}}
              </PixButton>
              {{#unless @organization.isArchived}}
                <PixButton @variant="error" @size="small" @triggerAction={{@toggleArchivingConfirmationModal}}>
                  Archiver l'organisation
                </PixButton>
              {{/unless}}
            </div>
          {{/if}}
        </div>
        <div>
          <PixButtonLink
            @variant="secondary"
            @href={{this.externalURL}}
            @size="small"
            target="_blank"
            rel="noopener noreferrer"
          >Tableau de bord
          </PixButtonLink>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function keys(obj1) {
    return Object.keys(obj1);
}
const FeaturesSection = template(`
  <h3 class="page-section__title page-section__title--sub">{{t
      "components.organizations.information-section-view.features.title"
    }}
    :
  </h3>
  <ul class="organization-information-section__details__list">
    {{#each (keys Organization.featureList) as |feature|}}
      {{#let
        (get @features feature) (concat "components.organizations.information-section-view.features." feature)
        as |organizationFeature featureLabel|
      }}
        <li>
          {{#if (eq feature "SHOW_NPS")}}
            <Feature @label={{t featureLabel}} @value={{organizationFeature.active}}>
              <a
                rel="noopener noreferrer"
                href={{organizationFeature.params.formNPSUrl}}
                target="_blank"
              >{{organizationFeature.params.formNPSUrl}}</a>
            </Feature>
          {{else if (eq feature "LEARNER_IMPORT")}}
            <Feature @label={{t featureLabel}} @value={{organizationFeature.active}}>
              {{organizationFeature.params.name}}
            </Feature>
          {{else if (eq feature "ATTESTATIONS_MANAGEMENT")}}
            <Feature @label={{t featureLabel}} @value={{organizationFeature.active}}>
              6ème
            </Feature>
          {{else}}
            <Feature @label={{t featureLabel}} @value={{organizationFeature.active}} />
          {{/if}}
        </li>
      {{/let}}
    {{/each}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
function displayBooleanState(bool1) {
    return bool1 ? 'common.words.yes' : 'common.words.no';
}
const Feature = template(`
  {{@label}}
  :
  {{#if (has-block)}}
    {{#if @value}}
      {{yield}}
    {{else}}
      {{t "common.words.no"}}
    {{/if}}
  {{else}}
    {{t (displayBooleanState @value)}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
